import { graphql } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';
import FlexibleContent from '~/components/FlexibleContent';
import SEO from '~/components/Global/SEO';
import CharitiesHero from '~/components/Hero/CharitiesHero';
import Layout from '~/components/Layout';
import { CharitiesPageQuery } from '../graphql-types';

interface CharitiesPageProps {
  data: CharitiesPageQuery;
}

const Charities: FunctionComponent<CharitiesPageProps> = ({
  data,
}): ReactElement => {
  const {
    title,
    charitiesPage: { charitiesHero },
    layouts,
  } = data.wpPage;
  return (
    <Layout>
      <SEO title={title} />

      <main>
        <CharitiesHero hero={charitiesHero} />
        <FlexibleContent pageName="charities" layouts={layouts} />
      </main>
    </Layout>
  );
};

export default Charities;

export const query = graphql`
  query CharitiesPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      charitiesPage {
        charitiesHero {
          subheading
          heading
          content
          button {
            url
            title
          }
          image {
            ...Image
          }
        }
      }
      layouts {
        layouts {
          __typename
          ... on WpPage_Layouts_Layouts_TwoColImageText {
            subheading
            heading
            headingWidth
            content
            contentWidth
            image {
              ...Image
            }
            imagePosition
            backgroundColour
            contentLink
            link {
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_Compliance {
            subheading
            heading
            content
            feature {
              heading
              description
            }
          }
          ... on WpPage_Layouts_Layouts_TextWithLogosGrid {
            subheading
            heading
            headingWidth
            logoGrid {
              image {
                ...Image
              }
            }
            gridColumns
          }
          # ... on WpPage_Layouts_Layouts_charities_faqs {
          #   id
          #   subheading
          #   heading
          #   content
          #   link {
          #     url
          #     title
          #   }
          #   faq {
          #     question
          #     answer
          #   }
          # }
          ... on WpPage_Layouts_Layouts_FeaturesContentGrid {
            column {
              icon {
                ...Image
              }
              heading
              content
            }
            backgroundColour
          }
          ... on WpPage_Layouts_Layouts_TwoColTextWithContentGrid {
            subheading
            heading
            headingWidth
            content
            column {
              icon {
                ...Image
              }
              heading
              content
            }
            backgroundColour
          }
          ... on WpPage_Layouts_Layouts_Cta {
            heading
            content
            button {
              title
              url
            }
            image {
              ...Image
            }
            mobileImage {
              ...Image
            }
            imagePosition
          }
        }
      }
    }
  }
`;
